export type FileCategories = "photo" | "passport" | "snils"
  | "medical_certificate" | "fluorography" | "medical_vaccinations"
  | "certificate_policy" | "personal_military" | "personal_privileges_scan"
  | "personal_olympiads_scan" | "personal_achievements_scan" | "form"
  | "application" | "agreement" | "itmo_stars_portfolio" | "itmo_stars_additional"
  | "kronbars_stars_career" | "kronbars_stars_career" | "kronbars_stars_letter"
  | "kronbars_stars_additional" | "diplom_scan" | "diplom_supplement" | "compatriot"
  | "exam_evaluation_scan" | "special_quota" | "track" | "special_condition_scan"
  | "target_admission" | "contract" | "university_licence";

export enum MimeType {
  Jpeg = "image/jpeg",
  Png  = "image/png",
  Pdf  = "application/pdf",
  Docx  = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
}
