import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiType } from "@App/providers/StoreProvider";
import { api } from "@Shared/api/createAxiosApi";
import getErrorMessage from "@/lib/_common/getErrorMessage";
import camelcaseKeys from "camelcase-keys";
import { IWindowProgramWithCost } from "@/types/window/forms";
import { getEmptyCostEducation } from "@/lib/_common/getEmptyCostEducation";

export interface fetchCompetitiveGroupsParams {
  year: number;
  degree: IDegree;
}

export const fetchProgramsWithCosts = createAsyncThunk<
  IWindowProgramWithCost[],
  fetchCompetitiveGroupsParams,
  ThunkApiType<string>
>("window/fetchProgramsWithCosts", async (params, thunkApi) => {
  const { rejectWithValue } = thunkApi;
  const { year } = params;

  try {
    const response = await api.get("/glossary/programs_with_costs", {
      params: { ...params, page: 1, limit: 100 },
    });
    const data = camelcaseKeys(
      response.data.result.programs
    ) as IWindowProgramWithCost[];
    return data.map((item) => ({
      ...item,
      costsEducations: item.costsEducations.length
        ? item.costsEducations
        : [{ ...getEmptyCostEducation(year) }],
    }));
  } catch (error: any) {
    return rejectWithValue(getErrorMessage(error));
  }
});
