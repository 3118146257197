enum RoutesNamesEnum {
  HOME = "/",

  LOGIN = "/login",

  PROGRAMS = "/programs",

  PERSONAL_PROGRAMS = "/personal/programs",
  PERSONAL_DATA = "/personal/personally",
  PERSONAL_ADDRESS = "/personal/address",
  PERSONAL_PHOTO = "/personal/photo",
  PERSONAL_EDUCATION = "/personal/education",
  PERSONAL_EXAMS = "/personal/exams",
  PERSONAL_RELATIVES = "/personal/relatives",
  PERSONAL_MEDICAL = "/personal/medical",
  PERSONAL_MILITARY = "/personal/military",
  PERSONAL_PRIVELEGES = "/personal/priveleges",
  PERSONAL_OLYMPIADS = "/personal/olympiads",
  PERSONAL_ACHIEVEMENTS = "/personal/achievements",
  PERSONAL_ITMOSTARS = "/personal/itmostars",
  PERSONAL_KRONBARS = "/personal/kronbars",
  PERSONAL_DOCUMENTS = "/personal/documents",
  PERSONAL_PAYMENT = "/personal/payment",
  PERSONAL_SPECIALTY = "/personal/specialty",
  PERSONAL_DORMITORY = "/personal/dormitory",

  WINDOW_DASHBOARD = "/window/[degree]",

  WINDOW_TABLE_NEW = "/window/[degree]/tables/new",
  WINDOW_TABLE_CASES = "/window/[degree]/tables/cases",
  WINDOW_TABLE_REJECTED = "/window/[degree]/tables/rejected",
  WINDOW_TABLE_ALL = "/window/[degree]/tables/all",
  WINDOW_TABLE_STATEMENTS = "/window/[degree]/tables/exams",
  WINDOW_TABLE_DOCUMENTS = "/window/[degree]/tables/documents",
  WINDOW_FORMS = "/window/[degree]/forms",
  WINDOW_TABLE_SSPVO = "/window/[degree]/tables/sspvo",

  WINDOW_CONTRACT_APPLICATIONS = "/tables/all?financing=contract&case_formed=1",

  WINDOW_TABLE_IA = "/window/[degree]/examination/ia",
  WINDOW_TABLE_KRONBARS_STARS = "/window/[degree]/examination/kronbars-stars",
  WINDOW_TABLE_ITMO_STARS = "/window/[degree]/examination/itmo-stars",
  WINDOW_TABLE_ARTICLES = "/window/[degree]/examination/articles",
  WINDOW_TABLE_EMAIL = "/window/[degree]/examination/email",
  WINDOW_TABLE_COMPETITION_PORTFOLIO = "/window/[degree]/examination/competitionPortfolio",
  WINDOW_TABLE_PROFESSIONAL_CONTEST = "/window/[degree]/examination/professional-contest",
  WINDOW_TABLE_PIGA = "/window/[degree]/examination/piga",
  WINDOW_TABLE_EXAMS = "/window/[degree]/examination/exams",
  WINDOW_TABLE_CONTESTS = "/window/[degree]/examination/contests",
  WINDOW_TABLE_TARGET_QUOTA = "/window/[degree]/examination/target-quota",
  WINDOW_TABLE_SPECIAL_CONDITIONS = "/window/[degree]/examination/special-conditions",
  WINDOW_TABLE_SPECIAL_QUOTA = "/window/[degree]/examination/special-quota",
  WINDOW_TABLE_UNUSUAL_QUOTA = "/window/[degree]/examination/unusual-quota",

  WINDOW_ORDERS_FORMATION = "/window/[degree]/orders/formation",
  WINDOW_ORDERS_GROUPS_ENTRY = "/window/[degree]/orders/groupsEntry",
  WINDOW_ORDERS_GROUPS_REPORT_WITHOUT_GROUPS = "/window/[degree]/orders/reportWithoutGroups",

  WINDOW_RATINGS_LIST = "/window/[degree]/ratings/list",
  WINDOW_RATINGS_RANKING = "/window/[degree]/ratings/ranking",

  WINDOW_STATISTICS_BY_PROGRAMS = "/window/[degree]/statistics/by_programs",
  WINDOW_STATISTICS_COMPETITIVE_GROUPS = "/window/[degree]/statistics/competitive_groups",
  WINDOW_STATISTICS_FILED_CASES = "/window/[degree]/statistics/filed_cases",
  WINDOW_STATISTICS_BY_FACULTIES = "/window/[degree]/statistics/by_faculties",

  QUESTIONNAIRE_PERSONAL_PROGRAMS = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/programs",
  QUESTIONNAIRE_PERSONAL_PROGRAMS_SELECT = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/programs-select",
  QUESTIONNAIRE_PERSONAL_DATA = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/personally",
  QUESTIONNAIRE_PERSONAL_ADDRESS = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/address",
  QUESTIONNAIRE_PERSONAL_PHOTO = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/photo",
  QUESTIONNAIRE_PERSONAL_EDUCATION = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/education",
  QUESTIONNAIRE_PERSONAL_EXAMS = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/exams",
  QUESTIONNAIRE_PERSONAL_RELATIVES = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/relatives",
  QUESTIONNAIRE_PERSONAL_MEDICAL = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/medical",
  QUESTIONNAIRE_PERSONAL_MILITARY = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/military",
  QUESTIONNAIRE_PERSONAL_PRIVELEGES = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/priveleges",
  QUESTIONNAIRE_PERSONAL_OLYMPIADS = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/olympiads",
  QUESTIONNAIRE_PERSONAL_ACHIEVEMENTS = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/achievements",
  QUESTIONNAIRE_PERSONAL_ITMOSTARS = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/itmostars",
  QUESTIONNAIRE_PERSONAL_KRONBARS = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/kronbars",
  QUESTIONNAIRE_PERSONAL_DOCUMENTS = "/window/[degree]/questionnaire/[userId]/[studentDetailsId]/documents",
}

export default RoutesNamesEnum;
