
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { AppProps } from "next/app";
import { FC, PropsWithChildren } from "react";
import ModalContext from "@/context/ModalProvider";
import { ProvideQueryParams } from "@/context/ProvideQueryParams";
import { AuthProvider } from "@App/providers/AuthProvider";
import { StoreProvider } from "@App/providers/StoreProvider";
import { ErrorBoundary } from "@App/providers/ErrorBoundary";
import Main from "./Main";
import Custom404 from "./404";
import "@/styles/main.scss";
import { RouteProtector } from "@App/providers/RouteProtector";
import { LanguagesEnum } from "@App/providers/AuthProvider/types/authTypes";
const NOT_FOUND_ROUTE = "/404";
const App: FC<PropsWithChildren<AppProps>> = (props) => {
    const route = props.router.route;
    const locale = props.router.locale as LanguagesEnum;
    if (route === NOT_FOUND_ROUTE) {
        return <Custom404 />;
    }
    return (<StoreProvider>
      <AuthProvider lang={locale}>
        <RouteProtector>
          <ErrorBoundary>
            <ProvideQueryParams>
              <ModalContext>
                <Main {...props}/>
              </ModalContext>
            </ProvideQueryParams>
          </ErrorBoundary>
        </RouteProtector>
      </AuthProvider>
    </StoreProvider>);
};
const __Next_Translate__Page__194ffffcd50__ = App;

    export default __appWithI18n(__Next_Translate__Page__194ffffcd50__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  